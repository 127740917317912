main {
    min-height: calc(70vh);
}

footer {
    position: relative;
    left: 0;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #F5F8FF;
  }

#footerBlock {
    color: #99A1BA
}
