h1 {
    font-weight: 800;
}
h2 {
    font-weight: 800;
}
h3 {
    font-weight: 600;
}
h4 {
    font-weight: 500;
}
h5 {
    font-weight: 500;
}
