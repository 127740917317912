.header-nav {

    a:not(.button) {
		@include font-family(heading);
	}

}
#headerNavLink {
	text-align: end;
	text-transform: none;
	color: #000000;
	padding-left: 1.5rem;
}
#headerPhoneLink {
	text-align: end;
	text-transform: none;
	color: #4064FD;
	padding-left: .5rem;
}

@media only screen and (max-width: 1400px) {
	#headerNavLink {
		padding-left: 0;
	}
	#headerPhoneLink {
		padding-left: 0;
	}
}
