
#leftSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow-wrap: break-word;
    font-size: 55px;
    }
#leftPayment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow-wrap: break-word;
    font-size: 55px;
}
#rightSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    //align-items: flex-end;
}
#rightPayment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.joinWaitlistBtn {
    padding-top: 3px;
}
#landingPageContainer {
    padding-top: 5rem;
    padding-bottom: 5rem;
    //background: linear-gradient(#F6E1E6, #B0B4FC);
    background: rgb(9, 6, 40);
}
#cashGlobe {
    align-items: center;
    justify-content: center;
    overflow: visible;
    offset: auto;
}
#scratch-section {
    //background-color: #D6EBCE;
    background-color: #4A61DD;
    padding-bottom: 5rem;
    padding-top: 5rem;
}
#leftFeature {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow-wrap: break-word;
    font-size: 55px;
}
#rightFeature {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
}
#features-container {
    background-color: #FFFFFF;
}
#features-section {
    padding-top: 5rem;
    padding-bottom: 5rem;
    border-bottom: 1px solid rgb(223, 227, 231);
}
#features-row {
    padding-bottom: 5rem;
    //border-bottom: 1px solid rgb(223, 227, 231);
}
#features-header-color {
    color: #536471;
    padding-bottom: 1rem;
}
#feature-title {
    color: #0A0B0D;
    font-weight: bold;
}
#feature-description {
    color: #0A0B0D;
}
#pfof-section {
    //background-color: #D6EBCE;
    background-color: #FFFFFF;
    padding-bottom: 5rem;
    padding-top: 2rem;
}
#legal-section {
    background-color: #FFFFFF;
    padding-bottom: 5rem;
    padding-top: 0;
}
#ux-section {
    background-color: #FFFFFF;
    padding-bottom: 5rem;
    padding-top: 5rem;
}
#leftPortfolio {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow-wrap: break-word;
}
#feature-color {
    color: #FFFFFF;
}
#featuredTitle {
    color: #FFFFFF;
}

.responsive {
    width: 100%;
    max-width: 14rem;
    height: auto;
}
.scratch {
    width: 100%;
    max-width: 16rem;
    height: auto;
}
.sparkle {
    width: 100%;
    max-width: 1rem;
    height: auto;
    padding-top: 0.3rem;
}
.feature-image {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
    padding-top: 0.3rem;
}
.ribbon {
    width: 100%;
    max-width: 6rem;
    height: auto;
}
.ios-badge {
    width: 100%;
    max-width: 10rem;
    height: auto;
}
.android-badge {
    width: 100%;
    max-width: 11.5rem;
    height: auto;
}
h1 {
    color: white;
}
h5 {
    font-size: 1rem;
    color: white;
}
p {
    font-size: 0.8rem;
}
.link-1 {
    color: #4A61DD;
    text-decoration: none;
    margin-top: -0.5rem;
}

@media only screen and (max-width: 1400px) {
    #landingPageContainer {
        margin-top: -2rem;
        padding-bottom: 1rem;
    }
    #scratch-section {
        //background-color: #D6EBCE;
        background-color: #4A61DD;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
    #leftSection {
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        padding-top: 3rem;
        align-items: center;
    }
    #rightSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    #leftFeature {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 3rem;
        order: 2
    }
    #leftPayment {
        display: flex;
        flex-direction: column;
        text-align: left;
        justify-content: center;
        //margin-bottom: 3rem;
        align-items: center;
    }
    #rightFeature {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
    }
    #rightPortfolio {
        text-align: left;
        display: flex;
        flex-direction: column;
    }
    #features-section {
        //background-color: #D6EBCE;
        background-color: #FFFFFF;
        padding-bottom: 1rem;
        padding-top: 1rem;
    }
    #features-row {
        padding-bottom: 1rem;
        //border-bottom: 1px solid rgb(223, 227, 231);
    }
    #pfof-section {
        //background-color: #D6EBCE;
        background-color: #FFFFFF;
        padding-bottom: 1rem;
        padding-top: 0;
    }
    #legal-section {
        //background-color: #D6EBCE;
        background-color: #FFFFFF;
        padding-bottom: 1rem;
        padding-top: 0;
    }
    #ux-section {
        background-color: #FFFFFF;
        padding-bottom: 1rem;
        padding-top: 2rem;
    }
    #leftPortfolio {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 3rem;
        order: 2
    }
    #rightPortfolio {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
    }
    #featuredTitle {
        text-align: center;
        color: #FFFFFF;
    }
    #cashGlobe {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        min-height: auto;
    }
    #cta-button {
        text-align: center;
    }
    .responsive {
        width: 100%;
        max-width: 12rem;
        height: auto;
    }
    .scratch {
        width: 100%;
        max-width: 14rem;
        height: auto;
    }
    .sparkle {
        width: 100%;
        max-width: 0.8rem;
        height: auto;
        padding-top: 0.4rem;
    }
    .ribbon {
        width: 100%;
        max-width: 5rem;
        height: auto;
    }
    h1 {
        font-size: 1.5rem;
    }
    h5 {
        font-size: 0.8rem;
    }
    p {
        font-size: 0.8rem;
    }
  }
