#thankyou {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-top: 14rem;
    padding-bottom: 14rem;
    display:block;
}
#verification {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding-top: 14rem;
    padding-bottom: 14rem;
    display: block;
}
#passwordreset {
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    padding-top: 8rem;
    padding-bottom: 8rem;
    display: block;
}
#image {
    width: 100%;
    max-width: 2rem;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 1400px) {
    #thankyou {
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        padding-top: 12rem;
        padding-bottom: 12rem;
        display:block;
    }
    #verification {
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        padding-top: 12rem;
        padding-bottom: 12rem;
        display: block;
    }
    #passwordreset {
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding-top: 6rem;
        padding-bottom: 3rem;
        display: block;
    }
    #image {
        width: 100%;
        max-width: 2rem;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}
