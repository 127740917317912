
#NftExplorerTitleRow {
    text-align: center;
    justify-content: center;
    padding-top: 100px;
    align-items: center;
}

#NftExplorerFormRow {
    text-align: center;
    justify-content: center;
    align-items: center;
}
#NftExplorerTitle {
    line-height: 0.3;
    word-spacing: 10px;
}

#cardImage {
    //width: 100%;
    height: 260px;
    margin: auto;
    border-radius: 0;
}

#cardText {
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-weight: 500;
    font-size: 0.75rem;
    color: white;
    padding: 0 15px;
    margin-bottom: 0;
}
#cardLink {
    overflow-wrap: break-word;
    word-wrap: break-word;
    font-weight: 400;
    font-size: 0.75rem;
    color: #4064FD;
    padding: 0 15px;
}
.cardTitle {
    font-weight: 800;
    font-size: 1rem;
    color: white;
    padding: 0 15px;
}

#cardBody {
    // border-radius: 10rem;
    max-width: 254px;
    border: 1px solid #252838;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 0 0 15px;
}

#searchField {
    display: flex;
    // justify-content: center;
}

#cardRow {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.imageDisclosure {
    //color: #99A1BA;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 0.8rem;
    padding: 0 15px;
}

#errorIcon {
    height: 1em
}
