.signupContainer {
  margin-top: 1%;
}

.rowBadges {
  padding-top: 2%;
}

.hero-signup-QR {
    margin-top: 3%;
    width:  auto;
    height: 15vh;
    background-size: cover;
    background-color: white;    
}

.badges
{
  width: 100%;
  text-align: center;
  margin-bottom: 2%;
}
.badge1,
.badge2
{
  display: inline-block;
  overflow: hidden;
  height: 83px;
  margin-top: 2%;
}
.badge2
{
  margin-left: 50px;
  width: 143px;
}
.badge1 > img
{
  margin-left: 0px;
  width: 143px;
}
.badge2 > img
{
  width: 143px; 
}

.badge2Mobile > img {
    width: 143px; 
    margin-top: 10px; 
    margin-bottom: 20px;
}

.badge1Mobile > img {
    margin-left: 0px;
    width: 143px;
    margin-top: 20px; 
}