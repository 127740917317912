#spinnerLoading {
    color:  #4064FD;
    position: relative;
    top: 50%;
}

#spinnerContainer {
    width: 100%;
    height: 800px;
    text-align: center;
}
