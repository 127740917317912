.button {
	@include font-family(heading);

	&:not(.button-dark):not(.button-primary):not(.button-secondary) {
		background: #4064FD;
		color: white;

		&:hover {
			background-color: rgba(33, 70, 226, 1);
		}
	}
}
